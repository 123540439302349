import React from "react"
import { SiWebpack } from "react-icons/si"
import { BsFiletypePng } from "react-icons/bs"

function ToolInfo(props) {
  return (
    <div>
      <div className="page-steps container">
        <h1 className="page-text">
          How to use a WebP to PNG converter
        </h1>
        <span className="page-text01">

Enhance image accessibility using our user-friendly WebP to PNG converter. Effortlessly convert WebP files into versatile PNG format, ensuring optimal cross-device usability and a seamless visual experience across diverse platforms.
          </span>
        <div className="page-container01">
          <div className="page-step">
            <div className="page-container02">
              <div className="page-line"/>
              <div className="page-container03">
                <SiWebpack size={30} style={{ color: "#00000080" }} />
              </div>
              <div className="page-line1"/>
            </div>
            <div className="page-container04">
              <h1 className="page-text02"> Click on "Upload WebP image file" and select
                the WebP file from your computer that you want to convert.</h1>
            </div>
          </div>

          <div className="page-step3">
            <div className="page-container11">
              <div className="page-line6"/>
              <div className="page-container12">

                <BsFiletypePng size={26} style={{ color: "#00000080" }} />
              </div>
              <div className="page-line7"/>
            </div>
            <div className="page-container13">
              <h3 className="page-text10">Once the conversion is complete, PNG file will automatically downloaded in
                your device.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolInfo